import styles from './Contacts.module.scss';

function Location() {
    return (
        <div>
            <svg display="none">
                <symbol viewBox="0 0 128 128" id="location">
                <g><path d="M62.594,112.389a1.755,1.755,0,0,0,1.383.708H64a1.749,1.749,0,0,0,1.377-.671c1.448-1.847,35.45-45.549,35.45-73.227a36.827,36.827,0,0,0-73.654,0c0,10.6,5.981,26.165,17.779,46.26C53.668,100.305,62.506,112.27,62.594,112.389ZM64,5.872A33.364,33.364,0,0,1,97.327,39.2c0,23.369-26.776,60.519-33.291,69.21C57.6,99.383,30.673,60.388,30.673,39.2A33.364,33.364,0,0,1,64,5.872Z"/><path d="M85.17,39.2A21.17,21.17,0,1,0,64,60.369,21.193,21.193,0,0,0,85.17,39.2ZM64,56.869A17.67,17.67,0,1,1,81.67,39.2,17.69,17.69,0,0,1,64,56.869Z"/><path d="M81.844,101.166a1.751,1.751,0,0,0-.426,3.475c15.067,1.849,19.942,5.337,19.942,6.736,0,2.627-13.186,7.74-37.36,7.74S26.64,114,26.64,111.377c0-1.5,4.781-4.569,17.613-6.425a1.751,1.751,0,0,0-.5-3.465c-9.4,1.361-20.612,4.18-20.612,9.89,0,8.247,24.435,11.24,40.86,11.24s40.86-2.993,40.86-11.24C104.86,104.951,90.44,102.222,81.844,101.166Z"/></g>

                </symbol>
            </svg>
            <svg className={styles.location}>
                <use href="#location"></use>
            </svg>
        </div>
    )
}

export default Location;