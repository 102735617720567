import styles from '../Contacts.module.scss';

function TelegramIcon(){
    return(
        <div>
            <svg display="none">
                <symbol viewBox="0 0 64 64" id="telegram">
                <g fill-rule="evenodd"><path d="m48 64h-32a16.0007 16.0007 0 0 1 -16-16v-32a16.0007 16.0007 0 0 1 16-16h32a16 16 0 0 1 16 16v32a16 16 0 0 1 -16 16" fill="#199bdf"/><path d="m30 18h18a9.0006 9.0006 0 0 0 .92-17.954c-.306-.017-.609-.046-.92-.046h-32a16.0007 16.0007 0 0 0 -16 16v32a30.0007 30.0007 0 0 1 30-30" fill="#32b4ff"/><path d="m48 32a16 16 0 1 0 16 16v-32a16 16 0 0 1 -16 16" fill="#0082be"/><path d="m45.08 17.032a3 3 0 0 1 4.11 3.229c-1.1654 7.579-3.302 21.467-4.4362 28.839a3 3 0 0 1 -4.7447 1.959c-4.0122-2.956-10.0276-7.388-13.0817-9.639a2 2 0 0 1 -.1868-3.064c2.7985-2.643 8.3723-7.907 12.2072-11.529a.5.5 0 0 0 -.6164-.782c-4.9171 3.207-12.4477 8.118-16.1385 10.525a5.9991 5.9991 0 0 1 -4.7328.795c-2.0323-.508-4.9637-1.241-7.5724-1.893a2 2 0 0 1 -.2784-3.789c9.0812-3.751 26.853-11.091 35.4707-14.651z" fill="#fff"/></g>

                </symbol>
            </svg>
            <svg className={styles.messenger_icon}>
                <use href="#telegram"></use>
            </svg>
        </div>
    )
}
export default TelegramIcon;