import styles from '../Contacts.module.scss';
function CallMessageIcon() {
    return (
        <div className={styles.call_mess}>
            <svg display="none">
                <symbol viewBox="0 0 64 64" id="call_mess">
                    <g><path d="m53.7467 27.28247a3.08035 3.08035 0 0 0 -4.25.00147l-3.52978 3.53806-12.72119-12.73044 3.53076-3.53125a3.00688 3.00688 0 0 0 0-4.248l-9.20749-9.20757a3.0215 3.0215 0 0 0 -4.23047.01758c-6.92318 5.68055-7.9335 16.1864-1.43994 22.59814l2.106 2.106h-19.81209a4.00458 4.00458 0 0 0 -4 4v23a4.00458 4.00458 0 0 0 4 4h20.01172l4.20508 5.499a3.59875 3.59875 0 0 0 6.373-1.373l.94433-4.126h3.47608a4.01855 4.01855 0 0 0 4-3.979l.04687-8.43853a14.95465 14.95465 0 0 0 7.668 2.12652c4.52506.111 8.94753-2.26883 12.019-5.81494a3.02562 3.02562 0 0 0 0-4.248zm-19.61425 25.544a2 2 0 0 0 -1.94971 1.55371l-1.10889 4.84521-4.29248-5.61377a1.99942 1.99942 0 0 0 -1.58887-.78515h-21v-23h23.812l11.26344 11.26338-.06518 11.73657zm24.53613-13.49419a10.98745 10.98745 0 0 1 -15.502 0l-18.4399-18.43994a11.07608 11.07608 0 0 1 .73584-16.23734l7.78174 7.78125-3.51709 3.51758a2.99388 2.99388 0 0 0 -.019 4.25586l14.13961 14.15042.001.00146a3.00215 3.00215 0 0 0 4.23975-.00244l3.53516-3.54345 7.78076 7.78076z" /><circle cx="10.193" cy="35.826" r="2" /></g>

                </symbol>
            </svg>
            <svg className={styles.call_message}>
                <use href="#call_mess"></use>
            </svg>
        </div>
    )
}
export default CallMessageIcon;