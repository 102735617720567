import styles from '../Contacts.module.scss';

function FacebookMessengerIcon (){
    return(
        <div>
             <svg display="none">
                <symbol viewBox="0 0 64 64" id="fb_mess_icon">
                <g fill-rule="evenodd"><path d="m48 64h-32a16.0007 16.0007 0 0 1 -16-16v-32a16.0007 16.0007 0 0 1 16-16h32a16 16 0 0 1 16 16v32a16 16 0 0 1 -16 16" fill="#0091ff"/><path d="m30 18h18a9.0006 9.0006 0 0 0 .92-17.954c-.306-.017-.609-.046-.92-.046h-32a16.0007 16.0007 0 0 0 -16 16v32a30.0007 30.0007 0 0 1 30-30" fill="#00b4ff"/><path d="m48 32a16 16 0 1 0 16 16v-32a16 16 0 0 1 -16 16" fill="#006eff"/><path d="m45.158 26.5478a1 1 0 0 0 -1.448-1.33c-2.328 1.746-5.031 3.7731-6.539 4.9038a1.9994 1.9994 0 0 1 -2.362.0275c-1.279-.9133-3.3-2.3566-4.589-3.2777a4.6764 4.6764 0 0 0 -2.719-.8714h-.001a2.7071 2.7071 0 0 0 -2.295 1.2718l-6.363 10.18a1 1 0 0 0 1.448 1.33c2.328-1.746 5.031-3.7731 6.539-4.9038a1.9994 1.9994 0 0 1 2.362-.0275c1.279.9133 3.3 2.3566 4.589 3.2777a4.6764 4.6764 0 0 0 2.719.8718h.001a2.7071 2.7071 0 0 0 2.295-1.2718zm-27.158 20.6469a20.6052 20.6052 0 0 1 -8-16.1947c0-11.59 9.858-21 22-21s22 9.41 22 21-9.858 21-22 21a22.9737 22.9737 0 0 1 -5.827-.7459l-5.34 2.4473a2 2 0 0 1 -2.833-1.8181z" fill="#fff"/></g>
                </symbol>
            </svg>
            <svg className={styles.messenger_icon}>
                <use href="#fb_mess_icon"></use>
            </svg>
        </div>
    )
}
export default FacebookMessengerIcon;