import styles from './Menu.module.scss';

function Burger() {
    
    return (
        <div>
            <svg display="none">
                <symbol viewBox="0 0 24 24" id="burger_menu">
                    {/* <g><path d="m30.93333 4.26667h-29.86666c-.58907 0-1.06667.4776-1.06667 1.06666s.4776 1.06667 1.06667 1.06667h29.86666c.58907 0 1.06667-.4776 1.06667-1.06667s-.4776-1.06666-1.06667-1.06666z" /><path d="m30.93333 14.93333h-29.86666c-.58907 0-1.06667.47761-1.06667 1.06667s.4776 1.06667 1.06667 1.06667h29.86666c.58907 0 1.06667-.47761 1.06667-1.06667s-.4776-1.06667-1.06667-1.06667z" /><path d="m30.93333 25.6h-29.86666c-.58907 0-1.06667.4776-1.06667 1.06667 0 .58906.4776 1.06667 1.06667 1.06667h29.86666c.58906 0 1.06667-.4776 1.06667-1.06667s-.4776-1.06667-1.06667-1.06667z" /></g> */}
                    <path d="m3 18h13v-2h-13zm0-5h10v-2h-10zm0-7v2h13v-2zm18 9.59-3.58-3.59 3.58-3.59-1.41-1.41-5 5 5 5z" />
                </symbol>
            </svg>
            <svg className={styles.burger}>
                <use href="#burger_menu"></use>
            </svg>
        </div>
    )
}
export default Burger;