import styles from '../Body.module.scss';
import { useInView } from 'react-intersection-observer';


function EducationIcon() {
    let [ref, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <div className={inView ? styles.educationicon_box : styles.educationicon_box_hidden} ref={ref}>
            <svg display="none">
                <symbol viewBox="0 0 512.049 512.049" id="education_icon">
                    <path d="m507.655 351.422-39.661-39.662v-136.044l25.893-9.335c5.944-2.144 9.908-7.781 9.913-14.101.005-6.318-3.951-11.963-9.893-14.114l-236.9-85.794c-3.299-1.195-6.916-1.195-10.215 0l-236.899 85.794c-5.919 2.144-9.869 7.754-9.893 14.049-.022 6.295 3.887 11.934 9.789 14.121l87.429 32.387v98.856c0 3.068.94 6.063 2.695 8.579.965 1.384 10.106 13.867 33.51 25.647 29.567 14.884 68.347 22.431 115.261 22.431 46.88 0 85.856-7.531 115.845-22.386 23.635-11.707 33.08-24.06 34.08-25.429 1.872-2.567 2.881-5.662 2.881-8.84v-97.89l36.505-13.161v125.23l-39.662 39.662c-2.813 2.813-4.394 6.628-4.394 10.606s1.58 7.794 4.394 10.606l39.662 39.661v33.277c0 8.284 6.716 15 15 15s15-6.716 15-15v-33.277l39.661-39.661c5.858-5.856 5.858-15.355-.001-21.212zm-255.755-268.993 192.761 69.809-195.906 70.629-190.144-70.438zm119.589 209.172c-51.279 46.142-203.657 40.851-244.271.15v-81.914l116.255 43.066c1.845.292 3.587 1.883 10.298.046l117.719-42.44v81.092zm81.505 93.269-22.843-22.842 22.843-22.843 22.842 22.843z" />
                </symbol>
            </svg>
            <svg className={styles.educationicon}>
                <use href="#education_icon"></use>
            </svg>
        </div>
    )
}
export default EducationIcon;